'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaData.factory:Proposals

 # @description

###
angular
  .module 'lpaData'
  .factory 'Proposals', [
      'Restangular'
      (Restangular) ->
        Restangular.service('lpa/services/dispatch/proposals')
    ]
